import { API_ENDPOINTS } from "../constants/ApiEndPoints";
import axiosInstance from "../shared/utils/axiosInstance";


export const getRoleDetails = async (params) => {
    console.log('params', params);
  try {
    const response = await axiosInstance.get(
      `${API_ENDPOINTS.GET_BASED_AUTHORIZATION_URL}`,
      {
        headers: {
            "TOKEN": params
          },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching dropdown data:", error);
    throw error;
  }
};

