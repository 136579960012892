import * as React from "react";
import {
  AuthenticationContext,
  SessionContext,
} from "@toolpad/core/AppProvider";
import { Account } from "@toolpad/core/Account";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../auth-config";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { generateTextImageURL } from "./generateTextImage";

// const demoSession = {
//   user: {
//     name: 'Sridharan',
//     email: 'sc3008@7-11.com'
//   },
// };

export default function AccountDemoSignedIn() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const { instance } = useMsal();

  const activeAccount = instance.getActiveAccount();
  const name = activeAccount.name;
  //const initials = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  var initials = `${name.split(" ")[0][0]}`;
  if(name.split(" ").length >1) {
    initials = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  }

  const imageUrl = generateTextImageURL(initials.split('').reverse().join(''));
  const userDetails = {
    user: {
      name: msalInstance.getActiveAccount().name,
      email: msalInstance.getActiveAccount().username,
      image: imageUrl,
    },
  };
  const [session, setSession] = React.useState(userDetails);
  useEffect(() => {
    const initialize = async () => {
      await instance.initialize();
    };

    initialize();
  }, []);
  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession(userDetails);
      },
      signOut: () => {
        instance.logoutRedirect().catch((error) => console.log(error));
      },
    };
  }, []);

  return (
    <AuthenticationContext.Provider value={authentication}>
      <SessionContext.Provider value={session}>
        {/* preview-start */}
        <Account />
        {/* preview-end */}
      </SessionContext.Provider>
    </AuthenticationContext.Provider>
  );
}
