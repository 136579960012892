import React, { useEffect, useState } from "react";
import Center from "../shared/Components/Center";
import Header from "../shared/Components/Header";
import "../shared/assets/scss/Home.scss";
import bulk from "../shared/assets/svg/bulk-logo.svg";
import rack from "../shared/assets/svg/rack-logo.svg";
import { useMsal } from "@azure/msal-react";
import { getRoleDetails } from "../services/AuthorizationServices";
import { FuelSupply } from "../constants/InitialState";
import { FuelSupplyAttributeIDs } from "../constants/Labels";
// import { ConfigUtil } from '../config/config-util';

// const { REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL } = ConfigUtil;

function Home() {
  const { instance } = useMsal();
  const [getFuelsData, setGetFuelsData] = useState([]);
  const activeAccount = instance.getActiveAccount();
  const isObjectEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };


  const getData = async () => {

    if (activeAccount) {
      try {
        const data = await getRoleDetails(activeAccount.idToken);
        if(data) {
          // data.data.userRoles = "OSA-Accounting"
          const userRoleData = data.data.userRoles;
          const splitData = userRoleData.split(',');
          console.log('role', splitData);
              if(splitData.includes('OSA-Admin')) {
                localStorage.setItem('userRole', 'Administrator');
              } else if (splitData.includes('OSA-Schedule')) {
                localStorage.setItem('userRole', 'Scheduler');
              } else if(splitData.includes('OSA-Contract')) {
                localStorage.setItem('userRole', 'Contractor');
              } else if(splitData.includes('OSA-Accounting')){
                localStorage.setItem('userRole', 'Accounting');
              }  
        }
        console.log('data', data.data.permissions);
        setGetFuelsData(data.data.permissions ? data.data.permissions.Fuels : []);
        console.log('data 2', data.data.permissions);
        console.log('data 3', isObjectEmpty(getFuelsData));
      } catch (error) {
        console.error(`Error fetching data:`, error);
      }
    }
  }
  useEffect(() => {
    getData();
  }, [])
  return (
    <div className="home-container">
      <Header />
      <div className="centered-display-content">
        {/* <Center> */}
          <div className="centered-box">
            <p className="app-greet-txt">Welcome!</p>
            <p className="app-select-heading">Please select application</p>
            <p className="app-tagline">
            Choose one application to proceed to a specific dashboard{" "}
            </p>
            <div className="fs-parent-container">
              <a href={process.env.REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL + "/osa/"} className={isObjectEmpty(getFuelsData) ? 'disabled' : getFuelsData.map((attr, i) => (attr?.permissionType === 'W' && attr?.attributeName === FuelSupplyAttributeIDs.BulkSupply ? 'attr-enabled' : 'attr-disabled'))}>
                <div className="bulk-supply supply-bg">
                  <div className="icon-bg">
                    <img
                      src={bulk}
                      className="logo-supply"
                      alt="bulk_supply_logo"
                      width="32"
                    />
                  </div>
                  <p className="app-name">Bulk Supply (OSA)</p>
                </div>
              </a>
              <a href={process.env.REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL + "/ics/"} className={isObjectEmpty(getFuelsData) ? 'disabled' : getFuelsData.map((attr, i) => (attr?.permissionType === 'W' && attr?.attributeName === FuelSupplyAttributeIDs.RackSupply ? 'attr-enabled' : 'attr-disabled'))}>
                <div className="rack-supply supply-bg">
                  <div className="icon-bg">
                    <img
                      src={rack}
                      className="logo-supply"
                      alt="rack_supply_logo"
                      width="32"
                    />
                  </div>
                  <p className="app-name">Rack Supply (ICS)</p>
                </div>
              </a>
            </div>
          </div>
        {/* </Center> */}
      </div>
    </div>

  );
}

export default Home;
