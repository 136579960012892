import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT_URL, // Replace with your API base URL
  headers: { 'Content-Type': 'application/json', 'Channel-Name': '7_Eleven_FS_Modernization' }
}); // Create the axios instance

// Add a request interceptor
axiosInstance.interceptors.request.use((request) => {
  return request;
  },
   (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

// Add a request interceptor
axiosInstance.interceptors.response.use((res) => {
  return res;
  },
   (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
				
		