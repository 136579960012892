export const generateTextImageURL = (text, width = 140, height = 70) => {
    // Create an offscreen canvas
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
  
    // Set background color (optional)
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    // Set text properties
    ctx.fillStyle = "black";
    ctx.font = "30px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
  
    // Draw text in the center
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
  
    // Convert canvas to a data URL
    return canvas.toDataURL("image/png");
  };

  