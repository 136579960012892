import "./App.scss";
import Home from "./pages/Home";
import {
  AuthenticatedTemplate,
  useMsal,
  MsalProvider,
} from "@azure/msal-react";
import { loginRequest } from "./auth-config";
import { useEffect, useState } from "react";
import { ConfigUtil } from "./config/config-util";

const { REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL, REACT_APP_SSO_REDIRECT_URI, REACT_APP_ENV } = ConfigUtil();

const WrappedView = () => {
  const { instance } = useMsal();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [activeAccount, setActiveAccount] = useState(null);

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        console.log("Initializing MSAL...");
        await instance.initialize();

        console.log("Calling handleRedirectPromise...");
        const response = await instance.handleRedirectPromise();
        console.log("handleRedirectPromise response:", response);

        const account = instance.getActiveAccount();
        setActiveAccount(account);
        setIsInitialized(true);
      } catch (error) {
        console.error("MSAL initialization failed:", error);
      }
    };

    initializeMsal();
  }, []);

  const handleRedirect = async () => {
    if (!isInitialized || isRedirecting) return; // Prevents premature redirects

    console.log("Redirecting to login...");
    setIsRedirecting(true);

    try {
      await instance.loginRedirect({
        ...loginRequest,
        prompt: "select_account",
      });
    } catch (error) {
      console.error("Login redirect failed:", error);
      setIsRedirecting(false);
    }
  };

  useEffect(() => {
    console.log("Config: ", ConfigUtil);
    console.log("Active Account:", activeAccount);
    console.log("ENV:::", JSON.stringify(process.env));
    console.log("NODE_ENV:", process.env.NODE_ENV || "testing");
    console.log("REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL:", REACT_APP_FUELS_SUPPLY_DEFAULT_HOST_URL || "testurl");
    console.log("REACT_APP_SSO_REDIRECT_URI:", REACT_APP_SSO_REDIRECT_URI || "redirecturl");
    console.log("REACT_APP_ENV:", REACT_APP_ENV || "env");

    if (isInitialized && !activeAccount && !isRedirecting) {
      console.log("No active account found. Redirecting to login...");
      setTimeout(() => handleRedirect(), 100); // Small delay to avoid race condition
    }
  }, [isInitialized, activeAccount, isRedirecting]);

  return (
    <div className="App">
      {activeAccount ? (
        <AuthenticatedTemplate>
          <Home />
        </AuthenticatedTemplate>
      ) : null}
    </div>
  );
};

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <WrappedView />
    </MsalProvider>
  );
};

export default App;
